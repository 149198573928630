export default function checkAuth({ next, router }) {

    if (localStorage.getItem('user') != null) {

        let roles = JSON.parse(localStorage.getItem('user')).roles

        //    alert(JSON.stringify(localStorage.getItem('user')))

        if (!roles.includes('ROLE_SUPER') && !roles.includes('ROLE_STAFF') && !roles.includes('ROLE_CLERK') && !roles.includes('ROLE_PROSECUTOR')) {

            return router.push({ name: 'login' });
        }
        return next();

    } else {
        return router.push({ name: 'error-404' });
    }
}