import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import FlagIcon from 'vue-flag-icon'
import i18n from './plugins/i18n'
import Notifications from "vue-notification"
import VueConfirmDialog from 'vue-confirm-dialog'
import VueHtmlToPaper from 'vue-html-to-paper'

Vue.config.productionTip = false
Vue.use(FlagIcon)
Vue.use(Notifications)
Vue.use(VueConfirmDialog)
Vue.use(VueHtmlToPaper)

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
