import api from './index'

class AuthService {
  login(user) {
    return api
      .post("auth/signin", {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  login2fa(login2faRequest) {
    return api
      .post("auth/signinWith2Fa", login2faRequest)
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.clear();
  }
}

export default new AuthService();
